import axios from 'axios';
import { DateTime } from "luxon";

const API_ADDRESS = process.env.VUE_APP_API_ADDRESS;
const JWT_SESSION_NAME = 'jwt';
const USER_DATA = 'userdata';
const EXP_DATA = 'sessionExp';
const USER_CREDENTIALS = 'NH2YyfF974p7E9M9VRG';
const USER_CREDENTIALS_TIMES = 3;
const LIMIT_IN_MINUTES_TO_GENERATE_NEW_TOKEN = 2;

const uuid = (a) => a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,uuid)
const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join('')
  );

  return JSON.parse(jsonPayload);
}
const encodePass = (email, password) => Array(USER_CREDENTIALS_TIMES).reduce(resultado => btoa(resultado), btoa(`${email}:${password}`));
const decodePass = (hash) => Array(USER_CREDENTIALS_TIMES).reduce(resultado => atob(resultado), atob(hash)).split(':');
const saveJwt = (jwt) => {
  sessionStorage.setItem(JWT_SESSION_NAME, jwt)
  sessionStorage.setItem(USER_DATA, JSON.stringify(parseJwt(jwt).user))
  sessionStorage.setItem(EXP_DATA, parseJwt(jwt).exp)
}
export const isLogged = () => {
  const token = sessionStorage.getItem(JWT_SESSION_NAME)
  return !(token === undefined || token === null || token.length === 0 || parseJwt(token).exp < Math.floor(Date.now() / 1000));
}
export const logOut = () => {
  sessionStorage.clear();
}
export const getUserData = () => {
  return JSON.parse(sessionStorage.getItem(USER_DATA));
}
const getUserCredentials = () => {
  return sessionStorage.getItem(USER_CREDENTIALS);
}
const getExpTimeSession = () => {
  return sessionStorage.getItem(EXP_DATA);
}
export const setUserData = (user) => {
  return sessionStorage.setItem(USER_DATA, JSON.stringify(user));
}
const getDefaultHeader = (contentType = '') => {
  let data = {
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem(JWT_SESSION_NAME),
      'x-correlation-id': uuid()
    }
  };
  if (contentType && contentType === 'formData') {
    data.headers['Content-Type'] = 'multipart/form-data';
  }
  return data;
}
export const login = async (email, password) => {
  try {
    const result = await axios.post(API_ADDRESS + '/user/token', {
      email,
      password
    })
    saveJwt(result.data.token)
    sessionStorage.setItem(USER_CREDENTIALS, encodePass(email, password))
    return true
  } catch (error) {
    throw new Error(error.response.data.error);
  }
}
export const callUrl = async (obj, url, method, contentType = '', external = false, resumed = true) => {
  try {
    const exp = getExpTimeSession();
    const diff = DateTime.fromSeconds(parseInt(exp, 10)).diff(DateTime.now(), ['minutes']);
    if (diff.toObject().minutes < LIMIT_IN_MINUTES_TO_GENERATE_NEW_TOKEN) {
      const credentials = decodePass(getUserCredentials());
      if (!login(credentials[0], credentials[1])) {
        logOut();
        return null;
      }
    }
    let data = null;
    if (external) {
      return await axios.post(API_ADDRESS + url, obj);
    }
    if (method === 'post') {
      data = await axios.post(API_ADDRESS + url, obj, getDefaultHeader(contentType))
    } else if (method === 'put') {
      data = await axios.put(API_ADDRESS + url, obj, getDefaultHeader(contentType))
    } else if (method === 'patch') {
      data = await axios.patch(API_ADDRESS + url, obj, getDefaultHeader(contentType))
    } else if (method === 'get') {
      data = await axios.get(API_ADDRESS + url, getDefaultHeader(contentType))
    } else if (method === 'delete') {
      await axios.delete(API_ADDRESS + url, getDefaultHeader(contentType))
      return true;
    }
    if (data) {
      if (resumed) {
        return data.data.data
      }
      return data.data
    }
    return null;
  } catch (error) {
    console.log('error', error);
    throw new Error(error.response.data.error);
  }
}
export const healthApi = async () => {
  return callUrl(null, '/health', 'get');
}
export const getEntity = async (entity) => {
  return callUrl(null, `/${entity}`, 'get');
}
export const viewEntity = async (id, entity) => {
  return callUrl(null, `/${entity}/${id}`, 'get');
}
export const saveEntity = async (obj, entity) => {
  return callUrl(obj, `/${entity}`, 'post');
}
export const updateEntity = async (obj, entity) => {
  return callUrl(obj, `/${entity}/${obj.id}`, 'put');
}
export const deleteEntity = async (id, entity) => {
  return callUrl(null, `/${entity}/${id}`, 'delete');
}
